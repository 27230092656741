import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStat } from '../../store/dashboardSlice';
import Icon from '../common/Icon';
import Loading from '../common/Loading';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const DashboardCard = ({ title, value, icon, loading = false, color = "primary" }) => {
    return (
        <div className="card card-dashboard-fig">
            <div className="card-body">
                <div className="fig-wrapper">
                    {loading ?
                        <Loading />
                        :
                        <div className="fig">
                            <h3>{value || '--'}</h3>
                            <p>{title}</p>
                        </div>
                    }

                    <div className={"illus illus-" + color}>
                        <Icon icon={icon} size="24" />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default function Dashboard() {

    let dispatch = useDispatch();

    const selectedApp = useSelector(state => state.app.selectedApp);

    if (selectedApp == null) {
        return (
            <div className="container-fluid">
                <div className="row mt-3 mb-3">
                    <div className="col">
                        <p>Please select an app to continue</p>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => dispatch(getStat(selectedApp.value)), [selectedApp]);

    let {
        subscriptionCount, loading
    } = useSelector(state => state.dashboard);

    return (
        <div className="container-fluid">
            <div className="row mt-3 mb-3">
                <div className="col">
                    <h3>Dashboard</h3>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Link to='/subscriptions'>
                        <DashboardCard title="Subscriptions" value={subscriptionCount} loading={loading} icon="users" />
                    </Link>
                </div>

                <div className="col">
                    {/* <DashboardCard title="" value={0} loading={loading} icon="book-open" color="danger"/> */}
                </div>

                <div className="col">
                    {/* <DashboardCard title="" value={0} loading={loading} icon="book" color="warning"/> */}
                </div>

                <div className="col">
                    {/* <DashboardCard title="" value={0} loading={loading} icon="award" color="success" /> */}
                </div>
            </div>
        </div>
    );
}