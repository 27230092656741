import Dashboard from "./dashboard/Dashboard";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ConfirmPopup from './common/ConfirmPopup';
import Subscriptions from "./subscription/Subscriptions";
import SubscriptionPopup from "./subscription/SubscriptionPopup";
import Apps from "./app/Apps";
import AppPopup from "./app/AppPopup";
import Notifications from "./notifications/Notifications";

const AdminPortal = () => {
    let history = useHistory();

    useSelector(state => {
        let accessToken = state.auth.accessToken;
        if (accessToken == null) {
            history.push('/login');
            return;
        }
    });

    return (
        <div className="wrapper">
            <Sidebar />

            <div className="main">
                <Navbar />

                <div className="content">
                    <Switch>
                        <Route path="/subscriptions" component={ Subscriptions } />
                        <Route path="/notifications" component={ Notifications } />
                        <Route path="/apps" component={ Apps } />
                        <Route path="/" component={Dashboard} />
                    </Switch>
                </div>


                <div className="footer">
                </div>

                <ConfirmPopup />
                <SubscriptionPopup />
                <AppPopup />
            </div>


        </div>
    );
};

export default AdminPortal;

