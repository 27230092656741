import Select from 'react-select';

export default function Dropdown(props) {
    const Styles = {
        valueContainer: (provided, _) => ({
            ...provided,
            height: "calc(1.5em + 0.75rem + 2px)",
        }),
    
        control: (provided, _) => ({
            ...provided,
            height: "calc(1.5em + 0.75rem + 2px)",
            minHeight: "calc(1.5em + 0.75rem + 2px)",
            borderRadius: "0.25rem",
            boxShadow: null,
            borderColor: "#a0cfee",
            "&:hover": {
                border: "1px solid #a0cfee"
            },
            "&:focus": {
                border: "1px solid #a0cfee"
            }
        }),
    
        placeholder: (provided, _) => ({
            ...provided,
            top: "45%"
        }),
    
        singleValue: (provided, _) => ({
            ...provided,
            top: "45%"
        }),
    
        indicatorContainer: (provided, _) => ({
            ...provided,
            height: "calc(1.5em + 0.75rem + 2px)"
        }),
    
        input: (provided, _) => ({
            ...provided,
            height: "calc(1.5em + 0.75rem + 2px)",
        })
    };

    return (
        <Select styles={Styles} {...props} />
    );
};