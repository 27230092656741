import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        loading: false,
        error: null,
        list: [],
        form: null,
        popup: false,
        offset: 0,
        limit: 100,
        deletingId: null,
    },
    reducers: {
        notificationsRequested: (state, action) => {
            state.loading = true;
        },
        notificationsReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
        },
        notificationsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        notificationRequested: (state, action) => {
            state.loading = true;
        },
        notificationCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list.push(action.payload);
            state.form = null;
        },
        notificationUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex( notification => notification.id === action.payload.id );
            state.list[index] = action.payload;
        },
        notificationDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter( notification => notification.id !== state.deletingId );
            state.deletingId = null;
        },
        notificationError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showNotificationPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        markDeleting: (state, action) => {
            state.deletingId = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        },
    }
});

const {
    notificationsRequested,
    notificationsReceived,
    notificationsError,
    notificationRequested,
    notificationError,
    notificationCreated,
    notificationUpdated,
    notificationDeleted,
    showNotificationPopup,
    closePopup,
    markDeleting,
} = notificationSlice.actions;

const getNotifications = (appId) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const offset = getState().offset ?? 0;
    const limit = getState().limit ?? 100;
    dispatch(
        actions.apiCallBegan(
            {
                url: `/adm/notifications?app_id=${appId}&offset=${offset}&limit=${limit}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: notificationsRequested.type,
                onSuccess: notificationsReceived.type,
                onError: notificationsError.type
            }
        )
    );
}

const createNotification = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/notifications',
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: notificationRequested.type,
                onSuccess: notificationCreated.type,
                onError: notificationError.type
            }
        )
    );
}

const updateNotification = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/notifications/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: notificationRequested.type,
                onSuccess: notificationUpdated.type,
                onError: notificationError.type
            }
        )
    );
}

const deleteNotification = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        markDeleting(data.id)
    );
    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/notifications/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: notificationRequested.type,
                onSuccess: notificationDeleted.type,
                onError: notificationError.type
            }
        )
    );
}

const sendTest = (notification, appId) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: `/adm/notifications/${notification.id}/test?app_id=${appId}`,
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: sendTestRequested.type,
                onSuccess: sendTestCompleted.type,
                onError: sendTestError.type
            }
        )
    );
}

export { 
    getNotifications, 
    createNotification, 
    updateNotification, 
    deleteNotification, 
    showNotificationPopup, 
    closePopup,
};

export default notificationSlice.reducer;