import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        loading: false,
        error: null,
        list: [],
        form: null,
        popup: false,
        offset: 0,
        limit: 100,
        deletingId: null,
    },
    reducers: {
        subscriptionsRequested: (state, action) => {
            state.loading = true;
        },
        subscriptionsReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
        },
        subscriptionsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        subscriptionRequested: (state, action) => {
            state.loading = true;
        },
        subscriptionCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list.push(action.payload);
            state.form = null;
        },
        subscriptionUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex( subscription => subscription.id === action.payload.id );
            state.list[index] = action.payload;
        },
        subscriptionDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter( subscription => subscription.id !== state.deletingId );
            state.deletingId = null;
        },
        subscriptionError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showSubscriptionPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        },
        sendTestRequested: (state, action) => {
            state.loading = true;
        },
        sendTestCompleted: (state, action) => {
            state.loading = false;
        },
        sendTestError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        markDeleting: (state, action) => {
            state.deletingId = action.payload;
        },
    }
});

const {
    subscriptionsRequested,
    subscriptionsReceived,
    subscriptionsError,
    subscriptionRequested,
    subscriptionError,
    subscriptionCreated,
    subscriptionUpdated,
    subscriptionDeleted,
    showSubscriptionPopup,
    closePopup,
    sendTestRequested,
    sendTestCompleted,
    sendTestError,
    markDeleting,
} = subscriptionSlice.actions;

const getSubscriptions = (appId) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const offset = getState().offset ?? 0;
    const limit = getState().limit ?? 100;
    dispatch(
        actions.apiCallBegan(
            {
                url: `/adm/subscriptions?app_id=${appId}&offset=${offset}&limit=${limit}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: subscriptionsRequested.type,
                onSuccess: subscriptionsReceived.type,
                onError: subscriptionsError.type
            }
        )
    );
}

const createSubscription = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/subscriptions',
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: subscriptionRequested.type,
                onSuccess: subscriptionCreated.type,
                onError: subscriptionError.type
            }
        )
    );
}

const updateSubscription = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/subscriptions/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: subscriptionRequested.type,
                onSuccess: subscriptionUpdated.type,
                onError: subscriptionError.type
            }
        )
    );
}

const deleteSubscription = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        markDeleting(data.id)
    );

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/subscriptions/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: subscriptionRequested.type,
                onSuccess: subscriptionDeleted.type,
                onError: subscriptionError.type
            }
        )
    );
}

const sendTest = (subscription, appId) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: `/adm/subscriptions/${subscription.id}/test?app_id=${appId}`,
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: sendTestRequested.type,
                onSuccess: sendTestCompleted.type,
                onError: sendTestError.type
            }
        )
    );
}

export { 
    getSubscriptions, 
    createSubscription, 
    updateSubscription, 
    deleteSubscription, 
    showSubscriptionPopup, 
    closePopup,
    sendTest,
};

export default subscriptionSlice.reducer;