import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNotification, getNotifications, showNotificationPopup } from '../../store/notificationSlice';
import Icon from '../common/Icon';
import InlinePopup from '../common/InlinePopup';
import NotificationPopup from './NotificationPopup';

export default function Notifications() {

    let dispatch = useDispatch();

    const selectedApp = useSelector(state => state.app.selectedApp);

    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let list = useSelector(state => state.notification.list);

    useEffect(() => {
        dispatch(getNotifications(selectedApp.value));
    }, [selectedApp]);

    const createNewNotification = (e) => {
        e.preventDefault();
        dispatch(showNotificationPopup());
    };

    const editNotificationClick = (notification) => (e) => {
        e.preventDefault();
        dispatch(showNotificationPopup(notification));
    }

    const deleteNotificationClick = (notification) => (e) => {
        e.preventDefault();
        setSelected(notification);
        setShowDelete(true);
    }
    
    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const renderRows = () => {
        if (list.length == 0) {
            return (
                <tr key="0">
                    <td colSpan="6">No data</td>
                </tr>
            );
        }
        return list.map((notification, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{notification.title}</td>
                    <td>{notification.message}</td>

                    <td className="table-actions">
                        <a href="/#" onClick={editNotificationClick(notification)}>
                            <Icon icon="edit-2" />
                        </a>

                        <a href="/#" onClick={deleteNotificationClick(notification)}>
                            <Icon icon="trash" />
                        </a>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Notifications</h5>

                <div className="card-actions">
                    <a href="/#" className="btn btn-primary" onClick={createNewNotification}>
                        <Icon size="16" icon="plus" />
                        Create New Notification
                    </a>
                </div>
            </div>

            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th className="sortable sort-asc">Title</th>
                        <th>Message</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {renderRows()}
                </tbody>
            </table>

            <NotificationPopup />

            <InlinePopup
                isShowing={showingDelete}
                hide={hideDeletePopup}
                title="Delete Notification"
                message="Are you sure you want to delete this notification?"
                action={deleteNotification(selected)} />
        </div>
    );
}