import { Link, useRouteMatch } from "react-router-dom";
import Dropdown from "./common/Dropdown";
import Icon from "./common/Icon";
import { useDispatch, useSelector } from "react-redux";
import { getApps, selectApp } from "../store/appSlice";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function Sidebar() {

    let list = useSelector( state => state.app.list );
    let selectedApp = useSelector( state => state.app.selectedApp );
    const apps = list.map((item) => { return { value: item.id, label: item.name }});
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getApps());
    }, []);

    const onChangeApp = (item) => {
        if (item != selectedApp) {
            history.push('/');
            dispatch(selectApp(item));
        }
    };

    return (
        <div className="sidebar">
            <Link to="/" className="sidebar-brand">
                <img className="brand-img" src="/img/logo.svg" alt="logo" />
            </Link>

            <nav className="sidebar-content">
                <ul>
                    <li>
                        <Dropdown options={apps} name="level" value={selectedApp} onChange={onChangeApp} />
                    </li>
                    <li>
                        <SideNavItem title="Dashboard" icon="monitor" path="/" />
                    </li>
                    <li>
                        <SideNavItem title="Subscriptions" icon="shield" path="/subscriptions" />
                    </li>
                    <li>
                        <SideNavItem title="Notifications" icon="message-square" path="/notifications" />
                    </li>
                    <li><hr/></li>
                    <li>
                        <SideNavItem title="Apps" icon="box" path="/apps" />
                    </li>

                </ul>
            </nav>
        </div>
    );
}

function SideNavItem({ title, icon, path }) {

    let match = useRouteMatch({
        path: path,
        exact: true,
        strict: false
    });

    return (
        <Link to={path} className={match ? "active" : ""}>
            <Icon icon={icon} size={16} />
            {title}
        </Link>
    );
}