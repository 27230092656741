import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, createNotification, updateNotification } from '../../store/notificationSlice';
import Icon from "../common/Icon";

const NotificationPopup = () => {

    let loading = useSelector(state => state.notification.loading);
    let popup = useSelector(state => state.notification.popup);
    let form = useSelector(state => state.notification.form);
    let app = useSelector(state => state.app.selectedApp);

    const initialState = {
        title: '',
        message: '',
        ...form
    };

    let [{
        title,
        message,
    }, setState] = useState(initialState);

    useEffect(() => { setState(initialState)}, [form]);

    const dispatch = useDispatch();

    const close = (e) => {
        e.preventDefault();
        dispatch(closePopup());
        // clear();
    };

    const create = (e) => {
        // Frontend validation
        // Should do with Formik

        dispatch(createNotification({
            title: title,
            message: message,
            app_id: app.value,
        }));
    }

    const update = (e) => {
        e.preventDefault();
        dispatch(updateNotification({
            id: form.id,
            title: title,
            message: message,
            app_id: app.value,
        }));
    }

    const change = (e) => {
        let {name, value} = e.target;
        setState(prevState => ({...prevState, [name]: value}))
    }

    return (
        <div className={ "popup" + (popup ? " popup-show" : "")}>
            <div className="popup-overlay"></div>

            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="card">
                        <div className="card-header">
                            { form ?
                                <h5 className="card-title">Edit Notification</h5>
                                : 
                                <h5 className="card-title">Create New Notification</h5>
                            }
                            <div>
                                <a href="/#" onClick={close}>
                                    <Icon icon="x"/>
                                </a>
                                
                            </div>
                        </div>

                        <div className="card-body">
                            <form >
                                <div className="form-group">
                                    <label>Title</label>
                                    <input type="text" className="form-control" value={ title } name="title" readOnly={ form } onChange={change} />
                                </div>
                                <div className="form-group">
                                    <label>Message</label>
                                    <input type="text" className="form-control" value={ message } name="message" readOnly={ form } onChange={change} />
                                </div>
                            </form>

                            <div className="actions">
                                { form ? 
                                    <button onClick={update} className="btn btn-primary">Update</button>
                                    :
                                    <button onClick={create} className="btn btn-primary">Create</button>
                                }   
                                <div className="fill"></div>

                                { loading && 
                                    <div className="loading">
                                        <div className="threedots">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationPopup;