import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice';
import uiSlice from './uiSlice';
import api from './middleware/api';
import storage from 'redux-persist/lib/storage';
import subscriptionSlice from './subscriptionSlice';
import appSlice from './appSlice';
import {
    persistStore, persistReducer, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import dashboardSlice from './dashboardSlice';
import websocketSlice from './websocketSlice';
import websocket from './middleware/websocket';
import notificationSlice from './notificationSlice';


const reducers = combineReducers({
    auth: authSlice,
    subscription: subscriptionSlice,
    app: appSlice,
    dashboard: dashboardSlice,
    ui: uiSlice,
    websocket: websocketSlice,
    notification: notificationSlice,
});

const persistConfig = {
    key: 'root',
    storage,
    // blacklist: ['tool']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }).concat(api, websocket),
});

const persistor = persistStore(store);

export { store, persistor };